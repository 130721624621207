
// Write your custom style. Feel free to split your code to several files
footer {
  padding: 80px 0 60px 0;
  text-align: center;
  background-color: #323232;
  color: #fff
}

footer figure {
    width: 120px;
    margin: 0 auto;
    margin-bottom: 30px;
}

footer p {
    font-weight: 600;
    letter-spacing: 1.5px;
    font-size: 12px;
    color: #777;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.voiciety-f:before, .voiciety:before {
    content: "V";
}

.social a.social-voiciety {
    font-family: Dosis,sans-serif;
    font-weight: 600;
}

.social a.social-voiciety:hover {
    height: 32px;
    width: 32px;
    background-color: #5ac8fa;
    border-radius: 50%;
    display: inline-block;
}

.social a.social-alexa:hover {
    color: #31C4F3;
}

.round {
    border-radius: 50%;
}

@keyframes fade {
    0%   { opacity: 0; }
    11.11%   { opacity: 1; }
    33.33%  { opacity: 1; }
    44.44%  { opacity: 0; }
    100% { opacity: 0; }
  }

  .fadein img { 
    opacity:0; 
    animation-name: fade; 
    animation-duration: 18s; // (n + 1) x 3, n = number of images 
    animation-iteration-count: infinite; 
    position:absolute; 
    right:0; 
}

.fadein img:nth-child(1) { animation-delay: 0s; }
.fadein img:nth-child(2) { animation-delay: 3s; }
.fadein img:nth-child(3) { animation-delay: 6s; }
.fadein img:nth-child(4) { animation-delay: 9s; }
.fadein img:nth-child(5) { animation-delay: 12s; }

  
@media only screen 
and (min-device-width : 375px) 
and (max-device-width : 667px) {
    .fadein img {
        display: none;
    }
}

@media only screen 
and (min-device-width : 375px) 
and (max-device-width : 812px)
and (-webkit-device-pixel-ratio : 3) {
    .fadein img {
        display: none;
    }   
}




